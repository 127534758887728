import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD
export const PayloadPage = Loadable(lazy(() => import('../pages/dashboard/payload/Payload')));
export const ExtensionPage = Loadable(lazy(() => import('../pages/dashboard/extension/Extension')));
export const TriggerPage = Loadable(lazy(() => import('../pages/dashboard/trigger/Trigger')));
export const RecordPage = Loadable(lazy(() => import('../pages/dashboard/record/Record')));
export const RequestPage = Loadable(lazy(() => import('../pages/dashboard/request/Request')));
export const AccountPage = Loadable(lazy(() => import('../pages/dashboard/account/Account')));


// MAIN
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));