// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  page404: "/404",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  trigger: path(ROOTS_DASHBOARD, "/trigger"),
  record: path(ROOTS_DASHBOARD, "/record"),
  request: path(ROOTS_DASHBOARD, "/request"),
  general: {
    payload: path(ROOTS_DASHBOARD, "/payload"),
    extension: path(ROOTS_DASHBOARD, "/extension"),
  },
  user: {
    account: path(ROOTS_DASHBOARD, "/account"),
  },
};
