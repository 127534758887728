import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  NewPasswordPage,
  ResetPasswordPage,
  PayloadPage,
  ExtensionPage,
  TriggerPage,
  RecordPage,
  RequestPage,
  AccountPage,
  //
  Page404,
  ComingSoonPage,
  MaintenancePage,
} from "./elements";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // index
    {
      path: "",
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> }
          ],
        },
      ],
    },

    // Dashboard
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "payload", element: <PayloadPage /> },
        { path: "extension", element: <ExtensionPage /> },
        { path: "trigger", element: <TriggerPage /> },
        { path: "record", element: <RecordPage /> },
        { path: "request", element: <RequestPage /> },
        { path: "account", element: <AccountPage /> },
      ],
    },

    // Main Routes
    {
      element: <CompactLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoonPage /> },
        { path: "maintenance", element: <MaintenancePage /> },
        { path: "404", element: <Page404 /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
