// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import { TbVirus } from "react-icons/tb";
import { GrTrigger } from "react-icons/gr";
import { MdHttp } from "react-icons/md";
import { RiAccountCircleLine } from "react-icons/ri";
import { MdOutlineExtension } from "react-icons/md";

// ----------------------------------------------------------------------

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Payload",
        path: PATH_DASHBOARD.general.payload,
        icon: <TbVirus />,
      },
      {
        title: "Extension",
        path: PATH_DASHBOARD.general.extension,
        icon: <MdOutlineExtension />,
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: "management",
    items: [
      {
        title: "Trigger",
        path: PATH_DASHBOARD.trigger,
        icon: <GrTrigger />,
      },
      {
        title: "Request",
        path: PATH_DASHBOARD.request,
        icon: <MdHttp />,
      },
    ],
  },

  // USER
  // ----------------------------------------------------------------------
  {
    subheader: "user",
    items: [
      {
        title: "Account",
        path: PATH_DASHBOARD.user.account,
        icon: <RiAccountCircleLine />,
      },
    ],
  },
];

export default navConfig;
